* {
  box-sizing: border-box;
  scroll-padding-top: 3em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  gap: 4em;
  width: auto !important;
  scroll-behavior: smooth;
  height: fit-content;
}

.responsive-pad {
  padding: 0 15%;
}

#root {
 width: 100%;
}

@media screen and (max-width: 1700px) {
  .responsive-pad {
    padding: 0 10%;
  }
}
@media screen and (max-width: 1536px) {
  .responsive-pad {
    padding: 0 7%;
  }
}
@media screen and (max-width: 900px) {
  .responsive-pad {
    padding: 0 3%;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
